<div class="background">
    <div class="navbar">
        <div class="nav-logos">
            <img src="../../../assets/img/age-filter/iqos_logo_edad.webp" alt="IQOS" width="auto" height="auto"
                class="iqos-logo">
            <a href="https://www.oxxo.com" target="_blank">
                <img src="../../../assets/img/age-filter/logo_oxxo_m.webp" alt="OXXO" width="auto" height="auto"
                    class="oxxo-logo">
            </a>
        </div>
    </div>
    <div class="text">
        <h1>Términos y Condiciones</h1>
        <p>
            Estimado usuario, gracias por preferirnos y participar en el registro de tu
            dispositivo IQOS ILUMA.
        </p>
        <p>
            En cumplimiento con la legislación en materia de protección de datos le
            facilitamos la siguiente información acerca del tratamiento de sus datos
            de carácter personal que usted nos proporciona al registrar el dispositivo
            adquirido en nuestras tiendas.
        </p>
        <p>
            El Responsable del Tratamiento es Cadena Comercial OXXO, S.A. de C.V.
            con domicilio en Edison 1235 Norte, colonia Talleres en Monterrey, Nuevo
            León, México, C.P. 64480, (en adelante, indistintamente “OXXO” o
            “Responsable”).
        </p>
        <p>Las finalidades del registro son:</p>
        <ol>
            <li>
                Que usted reciba 1,500 puntos dentro de nuestro Programa de
                lealtad, para ser redimidos en la compra de productos en nuestras
                tiendas OXXO.
            </li>
            <li>
                Que su información será compartida con Philip Morris México, S.A.
                de C.V. (PM) para que usted reciba asesoría y atención directa
                respecto a sus inquietudes en relación al uso de su dispositivo IQOS
                ILUMA de manera directa por parte de PM.
            </li>
            <li>
                Que usted reciba información acerca de promociones, descuentos,
                dinámicas y todo tipo de comunicación publicitaria de
                los productos de PM.
            </li>
        </ol>
        <p>
            Los datos que se tratarán serán: nombre, correo electrónico, teléfono y el
            número de su dispositivo. Sus datos personales no serán tratados
            posteriormente de manera incompatible con el fin previamente
            mencionado.
        </p>
        <p>
            La legitimación para el tratamiento de sus datos se basa el
            consentimiento que otorgue usted, el cual otorgará activamente al
            participar en la dinámica. No otorgar el consentimiento conlleva la
            imposibilidad de participar en la misma.
        </p>
        <p>
            OXXO, legítimamente necesita del uso del encargado del tratamiento
            para poder llevar a cabo el desarrollo de la dinámica. Los destinatarios de
            los datos son:
        </p>
        <p>
            <b>LEALTAD MERCADOTECNIA Y CONOCIMIENTO AGREGADOS, S.A.P.I. DE C.V.</b>
            , (en adelante, “Lealtad”) con domicilio en Calle Edison Norte, 1235,
            Colonia Talleres, Monterrey, Nuevo León, C.P. 64480 y el cual opera
            bajo la marca <b>SPIN PREMIA®</b> (en adelante, el “Programa de lealtad”).
        </p>
        <p>
            <b>PHILIP MORRIS MÉXICO, S.A. DE C.V.</b>, (en adelante, “PM”) con domicilio
            en Calle Lago Zurich #245, Edificio Presa Falcón, Piso 3, Col. Ampliación
            Granada, Alcaldía Miguel Hidalgo, C.P. 11529, CDMX, y el cual opera bajo la
            marca <b>IQOS ILUMA®</b> a fin de que usted pueda tramitar de manera
            directa la garantía extendida, reemplazo por daños, actualizaciones,
            soporte personalizado, y ofertas exclusivas y todo tipo de comunicación
            publicitaria de sus productos. El tratamiento de sus datos personales por
            parte de PM será realizado de conformidad con lo establecido en nuestro
            Aviso de Privacidad, mismo que podrá ser consultado en todo momento
            en la página de internet:
            <a href="https://www.pmiprivacy.com/mexico/es-la/"
                target="_blank">https://www.pmiprivacy.com/mexico/es-la/</a>.
        </p>
        <p>
            Hacemos de su conocimiento que, al registrarse, en el Programa de
            lealtad, usted está de acuerdo con participar en el mismo y, por ende, se
            entiende que usted ha leído los Términos y Condiciones que rigen su uso,
            mismos que podrán ser consultados en:
            <a href="https://spinpremia.com/terminos-y-condiciones-del-programa/"
                target="_blank">https://spinpremia.com/terminos-y-condiciones-del-programa/
            </a>.
        </p>
        <p>
            Si el Usuario no acepta los presentes Términos y Condiciones, deberá
            abstenerse de participar en el Programa de lealtad ya sea a través de su
            tarjeta física o digital o por cualquiera de sus Plataformas disponibles,
            incluyendo a través de las marcas aliadas que lo conforman. Por lo
            anterior, le solicitamos de la manera más atenta revise este documento
            detenidamente.
        </p>
        <p>
            Lealtad podrá realizar libremente, en cualquier momento y sin necesidad
            de previo aviso, modificaciones formales, procedimentales o sustanciales
            a los presentes Términos y Condiciones, estas incluyen, pero no se limitan
            a realizar correcciones, adiciones, mejoras o modificaciones al contenido,
            presentación, información, bases de datos y demás elementos del
            Programa de lealtad, por mencionar algunos (en adelante las
            “Actualizaciones”), siendo aplicables los Términos y Condiciones
            publicados en el sitio web <a href="https://spinpremia.com/terminos-y-condiciones-del-programa/oxxo/"
                target="_blank">https://spinpremia.com/terminos-y-
                condiciones-del-programa/oxxo/</a>.
        </p>
        <p>
            Le recordamos que el tratamiento de sus datos personales será realizado
            de conformidad con lo establecido en nuestro Aviso de Privacidad,
            mismo que podrá ser consultado en todo momento en nuestra página
            de internet: <a href="https://www.oxxo.com/aviso-de-privacidad-integral"
                target="_blank">https://www.oxxo.com/aviso-de-privacidad-integral</a>.
        </p>
        <p>
            Asimismo, en todo momento podrá directamente o por medio de algún
            representante legal, ejercer cualquiera de los derechos de acceso,
            rectificación, cancelación u oposición (en lo sucesivo “Derechos ARCO”),
            así como revocar y/o limitar su consentimiento para el tratamiento de sus
            datos personales enviando un correo electrónico a
            <a href="mailto:cmoxxo.arco@oxxo.com">cmoxxo.arco@oxxo.com</a>, o bien, llamando a OXXO ESCUCHA al teléfono
            sin costo <a href="tel:8183202020">01 (81) 83 20 20 20</a>, en ambos casos, siguiendo el procedimiento,
            requisitos y plazos para el ejercicio de sus Derechos ARCO señalado en
            nuestro Aviso de Privacidad.
        </p>
        <p>
            Los presentes Términos y Condiciones están sujetos y serán
            interpretados de acuerdo con la legislación aplicable vigente en la
            República Mexicana. Cualquier controversia que deba resolverse en
            virtud del uso del Programa de lealtad y/o los servicios que deriven de la
            misma, deberá someterse a la jurisdicción de los Tribunales de la Ciudad
            de Monterrey, Nuevo León, renunciando a cualquier otra jurisdicción que
            pudiese corresponderle a la parte actora por su domicilio presente o
            futuro, o por cualquier otra causa.
        </p>
    </div>
</div>